import React from 'react'
import style from './Featured.module.css'
import {
  featured_1,
  featured_2,
  featured_3,
  featured_4,
  featured_main,
} from '../../../Assets/Images/SidingPage/Featured'

const featuredImages = [
  { src: featured_1, alt: 'featured_1' },
  { src: featured_2, alt: 'featured_2' },
  { src: featured_3, alt: 'featured_3' },
  { src: featured_4, alt: 'featured_4' },
]

const Featured = () => (
  <div className={style.featured}>
    <div className="customContainer">
      <div className={style.titles}>
        <hr className={style.titles_line} />
        <h2 className={style.titles_title}>Featured Project</h2>
        <hr className={style.titles_line} />
      </div>

      <div className={style.wrap}>
        <img
          src={featured_main}
          alt="Featured Main"
          className={style.wrap_mainImg}
        />
        <div className={style.wrap_left}>
          <div>
            <h4 className={style.title}>Delancey Place</h4>
            <h6 className={style.subtitle}>
              Custom Cut and Colored Fiber Cement Panels by James Hardie
            </h6>
          </div>
          <div className={style.imagesWrap}>
            {featuredImages.map(({ src, alt }) => (
              <img
                key={alt}
                src={src}
                alt={alt}
                className={style.imagesWrap_img}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export { Featured }
