import React from 'react'
import WebHeader from './WebHeader/WebHeader'
import MobHeader from './MobHeader/MobHeader'

const Header = () => {
  return (
    <>
      <WebHeader />
      <MobHeader />
    </>
  )
}

export default Header
