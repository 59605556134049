import React from 'react'
import style from './Title.module.css'
const Title = ({ title }) => {
  return (
    <div className={style.wrap}>
      <h2 className={style.title}>{title}</h2>
      <div className={style.line}></div>
    </div>
  )
}

export default Title
