import React, { memo } from 'react'
import style from './Cement.module.css'
import Title from '../../../Components/Title/Title'
import {
  cementImg1,
  cementImg2,
  cementImg3,
  cementLogo1,
  cementLogo2,
  cementLogo3,
} from '../../../Assets/Images/SidingPage/Cement'

const cardItems = [
  {
    src: cementImg1,
    alt: 'Main Image',
    hoverText: 'Custom Colored Green Fiber Cement Lap Siding by James Hardie',
  },
  {
    src: cementImg2,
    alt: 'Image 1',
    hoverText: 'Artisan Collection Fiber Cement Siding by James Hardie',
  },
  {
    src: cementImg3,
    alt: 'Image 2',
    hoverText:
      'Sugi Ban Color Fiber Cement Lap Siding by James Hardie in combination with Golden Leaf Colored Accents',
  },
]

const features = [
  'SUSTAINABLE AND BIODEGRADABLE MATERIAL',
  'MOISTURE AND ROT RESISTANT',
  'LONG LIFE SPAN',
]

const Cement = () => (
  <div className={style.cement} id={'fiber'}>
    <div className="customContainer">
      <Title title="Fiber Cement Siding" />
      <div className={style.cement_wrap}>
        <div className={style.cement_wrap__left}>
          {cardItems.map(({ src, alt, hoverText }, index) => (
            <div key={index} className={style.cement_wrap__left_card}>
              <img
                src={src}
                alt={alt}
                className={style.cement_wrap__left_card__img}
              />
              <span className={style.cement_wrap__left_card__hover}>
                {hoverText}
              </span>
            </div>
          ))}
        </div>
        <div className={style.cement_wrap__right}>
          <p className={style.cement_wrap__right_txt}>
            Fiber cement siding, such as James Hardie® products, is renowned
            for its durability and aesthetic appeal. Made from a blend of
            cement, sand, and cellulose fibers, this siding offers a superior
            balance of performance and appearance, making it a preferred choice
            for both residential and commercial properties. James Hardie® fiber
            cement siding is non-combustible, and resistant to moisture, rot,
            and damage from pests like termites and woodpeckers. Engineered to
            endure various climates, it provides reliable protection and
            maintains its beauty over the long term.
          </p>
          <ul className={style.cement_wrap__right_ul}>
            {features.map((feature, index) => (
              <li key={index} className={style.cement_wrap__right_ul__li}>
                <FeatureIcon /> {feature}
              </li>
            ))}
          </ul>
          <div className={style.cement_wrap__right_label}>
            <ArrowIcon /> Hover over the photo to read more!
          </div>
          <div className={style.cement_wrap__right_logos}>
            {[cementLogo1, cementLogo2, cementLogo3].map((logo, index) => (
              <img
                key={index}
                className={style.cement_wrap__right_logos__logo}
                src={logo}
                alt={`cementLogo${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const FeatureIcon = memo(() => (
  <svg
    className={style.cement_wrap__right_ul__li_img}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <path
      d="M1 10.1667L6.71429 14.5L17 1.5"
      stroke="url(#paint0_linear_4385_661)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4385_661"
        x1="6.71429"
        y1="15.3125"
        x2="16.7765"
        y2="-0.889508"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FECB00" />
        <stop offset="1" stopColor="#FECB00" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
))
const ArrowIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5.5C17 5.33795 16.936 5.18253 16.8222 5.06794C16.7083 4.95335 16.5539 4.88897 16.3929 4.88897L2.07374 4.88897L5.89485 1.0444C6.00885 0.929668 6.07289 0.774055 6.07289 0.611796C6.07289 0.449538 6.00885 0.293925 5.89485 0.17919C5.78085 0.0644567 5.62624 -2.45931e-07 5.46502 -2.38884e-07C5.3038 -2.31837e-07 5.14919 0.0644567 5.03519 0.17919L0.178359 5.06739C0.121822 5.12415 0.0769656 5.19158 0.0463597 5.26581C0.0157539 5.34005 -2.43926e-07 5.41963 -2.40413e-07 5.5C-2.369e-07 5.58037 0.0157539 5.65995 0.0463598 5.73419C0.0769656 5.80842 0.121822 5.87585 0.178359 5.93261L5.03519 10.8208C5.09164 10.8776 5.15865 10.9227 5.2324 10.9534C5.30615 10.9842 5.3852 11 5.46502 11C5.62624 11 5.78085 10.9355 5.89485 10.8208C6.00885 10.7061 6.07289 10.5505 6.07289 10.3882C6.07289 10.2259 6.00885 10.0703 5.89485 9.9556L2.07374 6.11103L16.3929 6.11102C16.5539 6.11102 16.7083 6.04665 16.8222 5.93206C16.936 5.81747 17 5.66205 17 5.5Z"
      fill="#FECB00"
    />
  </svg>
))

export default memo(Cement)
