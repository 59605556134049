import React, { useState, useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import style from './Gallery.module.css'
import {
  first_Slide1,
  first_Slide2_1,
  first_Slide2_2,
  first_Slide3_1,
  first_Slide3_2,
  first_Slide4,
  fourth_Slide1,
  fourth_Slide2_1,
  fourth_Slide2_2,
  fourth_Slide3_1,
  fourth_Slide3_2,
  fourth_Slide4,
  gallery_arrow_left,
  gallery_arrow_right,
  second_Slide1,
  second_Slide2_1,
  second_Slide2_2,
  second_Slide3_1,
  second_Slide3_2,
  second_Slide4,
  third_Slide1,
  third_Slide2_1,
  third_Slide2_2,
  third_Slide3_1,
  third_Slide3_2,
  third_Slide4,
} from '../../../Assets/Images/DevelopmentsPage'

const slides = [
  {
    slide1: first_Slide1,
    slide2_1: first_Slide2_1,
    slide2_2: first_Slide2_2,
    slide3_1: first_Slide3_1,
    slide3_2: first_Slide3_2,
    slide4: first_Slide4,
  },
  {
    slide1: second_Slide1,
    slide2_1: second_Slide2_1,
    slide2_2: second_Slide2_2,
    slide3_1: second_Slide3_1,
    slide3_2: second_Slide3_2,
    slide4: second_Slide4,
  },
  {
    slide1: third_Slide1,
    slide2_1: third_Slide2_1,
    slide2_2: third_Slide2_2,
    slide3_1: third_Slide3_1,
    slide3_2: third_Slide3_2,
    slide4: third_Slide4,
  },
  {
    slide1: fourth_Slide1,
    slide2_1: fourth_Slide2_1,
    slide2_2: fourth_Slide2_2,
    slide3_1: fourth_Slide3_1,
    slide3_2: fourth_Slide3_2,
    slide4: fourth_Slide4,
  },
]

const totalSlides = [slides[slides.length - 1], ...slides, slides[0]]

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const transitionRef = useRef()

  const handleNext = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
      setIsTransitioning(true)
    }
  }

  const handlePrev = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      setIsTransitioning(true)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  useEffect(() => {
    if (isTransitioning) {
      transitionRef.current = setTimeout(() => {
        setIsTransitioning(false)
        if (currentIndex === 0) {
          setCurrentIndex(slides.length)
        } else if (currentIndex === slides.length + 1) {
          setCurrentIndex(1)
        }
      }, 500)
    }
    return () => clearTimeout(transitionRef.current)
  }, [currentIndex, isTransitioning])

  return (
    <div className={style.gallery}>
      <div className="customContainer">
        <div className={style.titles}>
          <hr className={style.titles_line} />
          <h2 className={style.titles_title}>Gallery</h2>
          <hr className={style.titles_line} />
        </div>

        <div className={style.carouselWrap}>
          <button
            className={`${style.carouselArrow} ${style.left}`}
            onClick={handlePrev}
            disabled={isTransitioning}
          >
            <img src={gallery_arrow_left} alt="" />
          </button>
          <button
            className={`${style.carouselArrow} ${style.right}`}
            onClick={handleNext}
            disabled={isTransitioning}
          >
            <img src={gallery_arrow_right} alt="" />
          </button>
          <div className={style.carousel} {...handlers}>
            <div
              className={style.carouselContainer}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: isTransitioning
                  ? 'transform 0.5s ease-in-out'
                  : 'none',
              }}
            >
              {totalSlides.map((slide, index) => (
                <div key={index} className={style.carouselSlide}>
                  <img
                    src={slide.slide1}
                    alt="slide1"
                    className={style.carouselSlide_1}
                  />
                  <div className={style.carouselSlide_center}>
                    <img
                      src={slide.slide2_1}
                      alt="slide2_1"
                      className={style.carouselSlide_21}
                    />
                    <img
                      src={slide.slide2_2}
                      alt="slide2_2"
                      className={style.carouselSlide_22}
                    />
                    <img
                      src={slide.slide3_1}
                      alt="slide3_1"
                      className={style.carouselSlide_31}
                    />
                    <img
                      src={slide.slide3_2}
                      alt="slide3_2"
                      className={style.carouselSlide_32}
                    />
                  </div>
                  <img
                    src={slide.slide4}
                    alt="slide4"
                    className={style.carouselSlide_4}
                  />
                </div>
              ))}
            </div>

            <div className={style.carouselDots}>
              {slides.map((_, index) => (
                <span
                  key={index}
                  className={`${style.carouselDot} ${
                    index + 1 === currentIndex ? style.activeDot : ''
                  }`}
                  onClick={() => {
                    if (!isTransitioning) setCurrentIndex(index + 1)
                  }}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Gallery }
