import React from 'react'
import { Cases } from './Cases/Cases'
import AboutRepairs from './AboutRepairs/AboutRepairs'
import MainBlock from './MainBlock/MainBlock'
import Numbers from './Numbers/Numbers'
import Form from '../../Modules/Form/Form'

const Repairs = () => {
  return (
    <div>
      <MainBlock />
      <Numbers />
      <AboutRepairs />
      <Cases />
      <Form />
    </div>
  )
}

export default Repairs
