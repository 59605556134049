import React from 'react'
import './services.css'
import { Button } from '@nextui-org/react'
import {
  ServiceBtn,
  VerticalLine,
} from '../../../Assets/Images/LandingPage/Services'
import { Link } from 'react-router-dom'

const Services = () => {
  return (
    <div>
      <div className="services_block_web">
        <div className="customContainer">
          <div className="services_title_block">
            <div>
              <h1 className="services_title">Explore our services</h1>
            </div>
            <div className="service_title_line"></div>
          </div>
          <div className="services_cards_block">
            <div className="services_card">
              <div className="service_card_bg service_card_bg1">
                <div className="services_text_block">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <p className="services_card_text">Siding</p>
                </div>
              </div>
              <div>
                <Link to={'/siding'}>
                  <Button className="services_btn">Learn More</Button>
                </Link>
              </div>
            </div>
            <div className="services_card">
              <div className="service_card_bg service_card_bg2">
                <div className="services_text_block">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <p className="services_card_text">Windows</p>
                </div>
              </div>
              <div>
                <Link to={'/windows'}>
                  <Button className="services_btn">Learn More</Button>
                </Link>
              </div>
            </div>
            <div className="services_card">
              <div className="service_card_bg service_card_bg3">
                <div className="services_text_block">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <p className="services_card_text">Repairs</p>
                </div>
              </div>
              <div>
                <Link to={'/repairs'}>
                  <Button className="services_btn">Learn More</Button>
                </Link>
              </div>
            </div>
            <div className="services_card">
              <div className="service_card_bg">
                <div className="services_text_block">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <p className="services_card_text">Developments</p>
                </div>
              </div>
              <div>
                <Link to={'/developments'}>
                  <Button className="services_btn">Learn More</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services_block_mobile">
        <div className="customContainer">
          <div className="services_title_block_mobile">
            <div>
              <h1 className="services_title_mobile">Services</h1>
            </div>
            <div className="service_title_line_mobile"></div>
          </div>
          <div>
            <p className="service_title_text_mobile">
              We’re here to help you with all your exterior solution needs.
              Whether you have questions, need a quote, or are ready to start
              your project, fill out the form below, and one of our
              representatives will get back to you promptly.
            </p>
          </div>
          <div className="services_cards_block_mobile">
            <div className="services_card_mobile">
              <div className="service_card_bg_mobile service_card_bg1">
                <div className="services_text_block_mobile">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <div className="services_btn_block_mobile">
                    <p className="services_card_text_mobile">Siding</p>
                    <Link to={'/siding'}>
                      <Button className="services_btn_mobile">
                        Learn More <img src={ServiceBtn} alt="ServiceBtn" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="services_card_mobile">
              <div className="service_card_bg_mobile service_card_bg2">
                <div className="services_text_block_mobile ">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <div className="services_btn_block_mobile">
                    <p className="services_card_text_mobile">Windows</p>
                    <Link to={'/windows'}>
                      <Button className="services_btn_mobile">
                        Learn More <img src={ServiceBtn} alt="ServiceBtn" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="services_card_mobile">
              <div className="service_card_bg_mobile service_card_bg3">
                <div className="services_text_block_mobile">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <div className="services_btn_block_mobile">
                    <p className="services_card_text_mobile">Repairs</p>
                    <Link to={'/repairs'}>
                      <Button className="services_btn_mobile">
                        Learn More <img src={ServiceBtn} alt="ServiceBtn" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="services_card_mobile">
              <div className="service_card_bg_mobile">
                <div className="services_text_block_mobile">
                  <img src={VerticalLine} alt="VerticalLine" />
                  <div className="services_btn_block_mobile">
                    <p className="services_card_text_mobile">Developments</p>
                    <Link to={'/developments'}>
                      <Button className="services_btn_mobile">
                        Learn More <img src={ServiceBtn} alt="ServiceBtn" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
