import React from 'react'
import MainBlock from './MainBlock/MainBlock'
import Navigation from './Navigation/Navigation'
import Faq from './Faq/Faq'
import Vinyl from './Vinyl/Vinyl'
import Form from '../../Modules/Form/Form'
import Weatherproofing from './Weatherproofing/Weatherproofing'
import Metal from './Metal/Metal'
import Cement from './Cement/Cement'
import { Featured } from './Featured/Featured'

const SidingPage = () => {
  return (
    <div>
      <MainBlock />
      <Navigation />
      <Vinyl />
      <Cement />
      <Featured />
      <Metal />
      <Weatherproofing />
      <Faq />
      <Form />
    </div>
  )
}

export default SidingPage
