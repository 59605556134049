import React, { useEffect } from 'react'
import MainThank from './MainThank/MainThank'

const ThankPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <MainThank />
    </div>
  )
}

export default ThankPage
