import React from 'react'
import { ToastContainer } from 'react-toastify'
import MainBLock from './MainBlock/MainBlock'
import Quality from './Quality/Quality'
import WhyUs from './WhyUs/WhyUs'
import Services from './Services/Services'
import Gallery from './Gallery/Gallery'
import Partners from './Partners/Partners'
import Form from '../../Modules/Form/Form'
import { NextUIProvider } from '@nextui-org/react'

const LandingPage = () => {
  return (
    <>
      <NextUIProvider>
        <ToastContainer />
        <MainBLock />
        <Quality />
        <WhyUs />
        <Services />
        <Gallery />
        <Partners />
        <Form />
      </NextUIProvider>
    </>
  )
}

export default LandingPage
