import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styles from './MobHeader.module.css'
// import { Link } from 'react-scroll'
import { Link } from 'react-router-dom'

import {
  HeaderBtn,
  MobileHeader,
} from '../../../Assets/Images/LandingPage/Header'
import { Button, useDisclosure } from '@nextui-org/react'
import { PopUp } from '../../../Pages/LandingPage/PopUp'

const MobHeader = () => {
  const [show, setShow] = useState(false)
  const modalState = useDisclosure()
  const { onOpen } = modalState

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleGetInTouch = () => {
    handleClose()
    onOpen()
  }

  return (
    <div>
      <header className={styles.wrap}>
        <div className={`customContainer ${styles.wrap_container}`}>
          <Link to={'/'}>
            <img
              src={MobileHeader}
              alt="logo"
              className={styles.wrap_container_logo}
            />
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            onClick={handleShow}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.66667 8.79183C2.66667 8.05545 3.26362 7.4585 4 7.4585H28C28.7364 7.4585 29.3333 8.05545 29.3333 8.79183C29.3333 9.52821 28.7364 10.1252 28 10.1252H4C3.26362 10.1252 2.66667 9.52821 2.66667 8.79183ZM2.66667 16.5005C2.66667 15.7641 3.26362 15.1672 4 15.1672H28C28.7364 15.1672 29.3333 15.7641 29.3333 16.5005C29.3333 17.2369 28.7364 17.8338 28 17.8338H4C3.26362 17.8338 2.66667 17.2369 2.66667 16.5005ZM2.66667 24.2091C2.66667 23.4728 3.26362 22.8758 4 22.8758H28C28.7364 22.8758 29.3333 23.4728 29.3333 24.2091C29.3333 24.9455 28.7364 25.5425 28 25.5425H4C3.26362 25.5425 2.66667 24.9455 2.66667 24.2091Z"
              fill="white"
            />
          </svg>
          <Modal
            show={show}
            onHide={handleClose}
            style={{
              top: '60px',
              width: '90%',
              transform: 'translateX(5%)',
            }}
          >
            <div
              onClick={handleClose}
              className={styles.wrap_container_closeBtn}
            >
              <p className={styles.wrap_container_closeText}>Close</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                onClick={handleClose}
              >
                <path
                  d="M2.00013 2L14 13.9999M14 2.00012L2.00012 14"
                  stroke="#D4D4D8"
                  stroke-width="2.1213"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <ul className={styles.wrap_container_modalWrap}>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="/siding"
                  offset={-30}
                  onClick={handleClose}
                >
                  Siding
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="/windows"
                  offset={-30}
                  onClick={handleClose}
                >
                  Windows/Doors
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="/developments"
                  onClick={handleClose}
                >
                  Developments
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="/repairs"
                  onClick={handleClose}
                >
                  Repairs
                </Link>
              </li>

              <Button
                onClick={handleGetInTouch}
                className={styles.container_mobile_btn}
              >
                Get in touch{' '}
                <img
                  className={styles.header_btn}
                  src={HeaderBtn}
                  alt="HeaderBtn"
                />
              </Button>
            </ul>
          </Modal>
        </div>
      </header>
      <PopUp modalState={modalState} title={'Get in touch'} />
    </div>
  )
}

export default MobHeader
