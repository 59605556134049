import styles from './Video.module.css'
import { mainVideo, mainVideoWebm } from '../../Assets/Video/Video'

const Video = () => {
  return (
    <div className={styles.videoContainer}>
      <video
        poster="../../Assets/Images/LandingPage/Gallery/galleryItem1.webp"
        className={styles.video}
        controls={false}
        autoPlay
        muted
        playsInline
        loop={true}
      >
        <source src={mainVideo} type="video/mp4" />
        <source src={mainVideoWebm} type="video/webm" />
      </video>
    </div>
  )
}

export default Video
