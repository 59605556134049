import React from 'react'
import './faq.css'
import { Accordion } from 'react-bootstrap'

const Faq = () => {
  return (
    <div className="faq_block">
      <div className="customContainer">
        <div className="faq_title_block">
          <div className="faq_title_line"></div>
          <div>
            <h1 className="faq_title">FAQ</h1>
          </div>
          <div className="faq_title_line"></div>
        </div>
        <div className="customContainerFaq">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Do I get a warranty on all of the work that’s being completed?
              </Accordion.Header>
              <Accordion.Body>
                Yes, we provide a comprehensive warranty on all the work we
                complete. This warranty covers both material and labor, ensuring
                that your investment is protected. Specific details of the
                warranty will be discussed with you before the project begins.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How do I know which siding is best for my property?
              </Accordion.Header>
              <Accordion.Body>
                Choosing the right siding depends on various factors, including
                your property’s architecture and personal preferences. We offer
                to do an estimate which will allow us to guide you through
                possible options such as a wide range of different types of
                vinyl, fiber cement, and metal. You can leave your contact
                information in the below “Get in Touch” form and we will reach
                out shortly.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                What if I can’t choose the color and style?
              </Accordion.Header>
              <Accordion.Body>
                If you’re unsure about the color and style, our experts are here
                to help. We can provide samples, show you previous projects, and
                even use digital tools to help you visualize different options
                on your property. Our goal is to ensure you’re confident and
                satisfied with your choice.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                When should siding be replaced?
              </Accordion.Header>
              <Accordion.Body>
                Siding should typically be replaced when you notice signs of
                wear such as cracks or fading. Other indicators include
                increased energy bills, visible mold or mildew, and moisture
                inside your home. If you have issues with your current siding,
                we offer repair services and siding replacement.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Why should I choose XLeum Construction?
              </Accordion.Header>
              <Accordion.Body>
                XLeum Construction stands out due to our commitment to quality,
                customer satisfaction, and expertise. We use high-quality
                materials, have experienced and certified professionals, and
                provide personalized services tailored to your needs. Our track
                record of successful projects and satisfied customers speaks to
                our dedication.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Do you do residential services too or just commercial?
              </Accordion.Header>
              <Accordion.Body>
                We offer both residential and commercial services. Whether you
                need siding, roofing, or other exterior services for your home
                or big project, XLeum Construction has the experience and
                resources to handle projects of all sizes and scopes
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
export default Faq
