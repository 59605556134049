import React from 'react'
import style from './MainBlock.module.css'
const MainBlock = () => {
  return (
    <div className={style.main}>
      <div className={`${'customContainer'} ${style.main_wrap}`}>
        <h2 className={style.main_title}>Siding Solutions</h2>
        <p className={style.main_desc}>
          Transform your property with XLeum Construction’s expertise and
          commitment to quality and customer satisfaction
        </p>
      </div>
    </div>
  )
}

export default MainBlock
