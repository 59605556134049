import React from 'react'
import styles from './Quality.module.css'
import {
  quality1,
  quality2,
  quality3,
} from '../../../Assets/Images/LandingPage/Quality/Quality'
const Quality = () => {
  const qualityItems = [
    {
      title: 'BEST QUALITY',
      imgSrc: quality1,
    },
    {
      title: 'TOP RATED',
      imgSrc: quality2,
    },
    {
      title: 'SATISFACTION GURANTEED',
      imgSrc: quality3,
    },
  ]

  return (
    <div className={styles.quality}>
      {qualityItems.map((item, index) => (
        <div className={styles.quality_item} key={index}>
          <h2 className={styles.quality_item__title}>{item.title}</h2>
          <img
            className={styles.quality_item__img}
            src={item.imgSrc}
            alt={item.title}
          />
        </div>
      ))}
    </div>
  )
}

export default Quality
