import React from 'react'
import './aboutwin.css'

const AboutWindows = () => {
  return (
    <div className="aboutwin_block">
      <div className="customContainer ">
        <div className="aboutwin_title_block">
          <div>
            <h1 className="aboutwin_title">Premium Products</h1>
          </div>
          <div className="aboutwin_title_line"></div>
        </div>
        <div className="aboutwin_content">
          <div className="aboutwin_content_left">
            <p className="aboutwin_content_text">
              We source the finest windows and doors directly from Europe,
              bringing premium quality and style right to your doorstep. These
              products are crafted with precision, offering superior durability
              and a modern design. Unlike standard options, European designs
              feature advanced technology and exceptional craftsmanship that
              elevate both performance and aesthetics. Whether you're renovating
              or building new, we ensure your project stands out with the best
              that Europe has to offer.
            </p>
          </div>
          <div className="aboutwin_content_right"></div>
        </div>
      </div>
    </div>
  )
}

export default AboutWindows
