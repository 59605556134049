import React, { useEffect, useState } from 'react'
import styles from './WebHeader.module.css'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
  HeaderBtn,
  HeaderLogo,
} from '../../../Assets/Images/LandingPage/Header'
import { Button, useDisclosure } from '@nextui-org/react'
import { PopUp } from '../../../Pages/LandingPage/PopUp'
import { NavLink } from 'react-router-dom'

const WebHeader = () => {
  const [show, setShow] = useState(null)
  const modalState = useDisclosure()
  const { onOpen } = modalState

  const controlNavBar = _.throttle(() => {
    const currentScrollY = window.scrollY
    if (lastScrollY < currentScrollY) {
      setShow('true')
    } else {
      setShow('false')
    }
    lastScrollY = currentScrollY
  }, 200)

  let lastScrollY = window.scrollY

  useEffect(() => {
    const handleScroll = () => {
      controlNavBar()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header
      className={`${styles.wrap} ${
        show === 'false'
          ? styles.wrapShow
          : show === 'true'
          ? styles.wrapHidden
          : null
      }`}
    >
      <div className={`customContainer ${styles.container}`}>
        <Link to={'/'}>
          <img src={HeaderLogo} alt="logo" className={styles.container_logo} />
        </Link>

        <div className={styles.header_nav_block}>
          <ul className={styles.container_linksWrap}>
            <li className={styles.container_linksWrap__link}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.container_linksWrap__link} ${styles.activeLink}`
                    : styles.container_linksWrap__link
                }
                to="/siding"
              >
                Siding
              </NavLink>
            </li>
            <li className={styles.container_linksWrap__link}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.container_linksWrap__link} ${styles.activeLink}`
                    : styles.container_linksWrap__link
                }
                to="/windows"
              >
                Windows/Doors
              </NavLink>
            </li>
            <li className={styles.container_linksWrap__link}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.container_linksWrap__link} ${styles.activeLink}`
                    : styles.container_linksWrap__link
                }
                to="/developments"
              >
                Developments
              </NavLink>
            </li>
            <li className={styles.container_linksWrap__link}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.container_linksWrap__link} ${styles.activeLink}`
                    : styles.container_linksWrap__link
                }
                to="/repairs"
              >
                Repairs
              </NavLink>
            </li>
          </ul>

          <Button onClick={onOpen} className={styles.container_btn}>
            Get in touch{' '}
            <img
              className={styles.header_btn}
              src={HeaderBtn}
              alt="HeaderBtn"
            />
          </Button>
        </div>
      </div>
      <PopUp modalState={modalState} title={'Get in touch'} />
    </header>
  )
}

export default WebHeader
