import React from 'react'
import style from './Navigation.module.css'
import {
  navigation1,
  navigation2,
  navigation3,
  navigation4,
} from '../../../Assets/Icons/SidingPage/Navigation/Navigation'
import { Link } from 'react-scroll'

const Navigation = () => {
  const items = [
    { img: navigation1, title: 'Vinyl', src: 'vinyl' },
    { img: navigation2, title: 'Fiber Cement', src: 'fiber' },
    { img: navigation3, title: 'Metal', src: 'metal' },
    { img: navigation4, title: 'Weatherproofing', src: 'weatherproofing' },
  ]

  return (
    <div className={style.nav}>
      <div className={`${'customContainer'} ${style.nav_wrap}`}>
        {items.map((item, index) => (
          <Link
            to={item.src}
            offset={-80}
            duration={500}
            key={index}
            className={style.nav_item}
          >
            <img
              className={style.nav_item__img}
              src={item.img}
              alt={item.title}
            />
            <h2 className={style.nav_item__title}>{item.title}</h2>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Navigation
