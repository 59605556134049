import React from 'react'
import style from './CardLeft.module.css'

const CardLeft = ({ data, imgClass, isFirstSvg }) => {
  return (
    <div className={style.card}>
      <div className={style.card_wrapper}>
        <p className={style.card_title}>{data.description}</p>
        <div className={style.card_wrap}>
          <ul className={style.card_list}>
            {data.features.map((feature, index) => (
              <li key={index} className={style.card_list__item}>
                <svg
                  className={style.card_list__img}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                >
                  <path
                    d="M1 10.1667L6.71429 14.5L17 1.5"
                    stroke="url(#paint0_linear_4385_661)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4385_661"
                      x1="6.71429"
                      y1="15.3125"
                      x2="16.7765"
                      y2="-0.889508"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FECB00" />
                      <stop offset="1" stopColor="#FECB00" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                {feature}
              </li>
            ))}
          </ul>
          <p className={style.card_desc}>
            {isFirstSvg && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="11"
                viewBox="0 0 17 11"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17 5.5C17 5.33795 16.936 5.18253 16.8222 5.06794C16.7083 4.95335 16.5539 4.88897 16.3929 4.88897L2.07374 4.88897L5.89485 1.0444C6.00885 0.929668 6.07289 0.774055 6.07289 0.611796C6.07289 0.449538 6.00885 0.293925 5.89485 0.17919C5.78085 0.0644567 5.62624 -2.45931e-07 5.46502 -2.38884e-07C5.3038 -2.31837e-07 5.14919 0.0644567 5.03519 0.17919L0.178359 5.06739C0.121822 5.12415 0.0769656 5.19158 0.0463597 5.26581C0.0157539 5.34005 -2.43926e-07 5.41963 -2.40413e-07 5.5C-2.369e-07 5.58037 0.0157539 5.65995 0.0463598 5.73419C0.0769656 5.80842 0.121822 5.87585 0.178359 5.93261L5.03519 10.8208C5.09164 10.8776 5.15865 10.9227 5.2324 10.9534C5.30615 10.9842 5.3852 11 5.46502 11C5.62624 11 5.78085 10.9355 5.89485 10.8208C6.00885 10.7061 6.07289 10.5505 6.07289 10.3882C6.07289 10.2259 6.00885 10.0703 5.89485 9.9556L2.07374 6.11103L16.3929 6.11102C16.5539 6.11102 16.7083 6.04665 16.8222 5.93206C16.936 5.81747 17 5.66205 17 5.5Z"
                  fill="#FECB00"
                />
              </svg>
            )}
            Hover over the photo to read more!
            {!isFirstSvg && (
              <svg
                className={style.card_desc__img}
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="11"
                viewBox="0 0 17 11"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.40413e-07 5.5C2.47496e-07 5.66205 0.0639613 5.81747 0.177817 5.93206C0.29167 6.04665 0.446089 6.11103 0.607104 6.11103L14.9263 6.11103L11.1051 9.9556C10.9912 10.0703 10.9271 10.2259 10.9271 10.3882C10.9271 10.5505 10.9912 10.7061 11.1051 10.8208C11.2191 10.9355 11.3738 11 11.535 11C11.6962 11 11.8508 10.9355 11.9648 10.8208L16.8216 5.93261C16.8782 5.87585 16.923 5.80842 16.9536 5.73419C16.9842 5.65995 17 5.58037 17 5.5C17 5.41963 16.9842 5.34005 16.9536 5.26581C16.923 5.19158 16.8782 5.12415 16.8216 5.06739L11.9648 0.179191C11.9084 0.122381 11.8414 0.0773162 11.7676 0.0465706C11.6939 0.0158249 11.6148 2.35394e-07 11.535 2.38884e-07C11.3738 2.45931e-07 11.2191 0.0644571 11.1051 0.179191C10.9911 0.293926 10.9271 0.449539 10.9271 0.611797C10.9271 0.774056 10.9911 0.929669 11.1051 1.0444L14.9263 4.88897L0.607104 4.88898C0.446089 4.88898 0.29167 4.95335 0.177817 5.06794C0.0639613 5.18253 2.33329e-07 5.33795 2.40413e-07 5.5Z"
                  fill="#FECB00"
                />
              </svg>
            )}
          </p>
          <div className={style.logo}>
            {data.logos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Logo ${index + 1}`}
                className={`${style.logo_img} ${imgClass}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLeft
