import React from 'react'
import style from './Weatherproofing.module.css'
import Title from '../../../Components/Title/Title'
import CardImg from '../../../Components/CardImg/CardImg'

import CardLeft from '../../../Components/CardLeft/CardLeft'
import {
  weather1,
  weather2,
  weather3,
  weatherIcon1,
  weatherIcon2,
} from '../../../Assets/Images/SidingPage/Weather'
const Weatherproofing = () => {
  const cardData = {
    description:
      "Weatherproofing is the process of protecting a building from the damaging effects of weather, including rain, wind, snow, and extreme temperatures. Proper weatherproofing helps prevent moisture infiltration, energy loss, and other weather-related damage, such as mold and mildew growth. By enhancing the durability and resilience of a building's exterior, weatherproofing ensures that your investment remains well-protected and retains its value over time.",
    features: [
      'PREVENTS WATER DAMAGE',
      'Mold and Mildew Resistant',
      'ENHANCED DURABILITY',
      'VALUE PROTECTION',
    ],
    logos: [weatherIcon1, weatherIcon2],
  }
  const cardItems = [
    {
      src: weather1,
      alt: 'Main Image',
      hoverText: 'WeatherSmart Building wrap by the Henry Company',
    },
    {
      src: weather3,
      alt: 'Image 1',
      hoverText:
        'BlueSkin VP160 Self-Adhering Weather Resistive Barrier by the Henry Company',
    },
    {
      src: weather2,
      alt: 'Image 2',
      hoverText:
        'BlueSkin VP160 Self-Adhering Weather Resistive Barrier by the Henry Company',
    },
  ]
  return (
    <div className={style.weather} id={'weatherproofing'}>
      <div className={'customContainer'}>
        <Title title={'Weatherproofing'} />
        <div className={style.weather_wrap}>
          <CardImg items={cardItems} cardClass={style.weather_order} />
          <CardLeft data={cardData} isFirstSvg={true} />
        </div>
      </div>
    </div>
  )
}

export default Weatherproofing
