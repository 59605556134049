import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './MainBlock.module.css'
import { Button } from '@nextui-org/react'

const MainThank = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate('/')
  }

  return (
    <div className={styles.mainBlock}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span className={styles.title_mod}></span> Thank you for your application!
        </h1>
        <br />
        <Button className={styles.button} variant="shadow" onClick={handleGoHome}>
          Return to the main page
        </Button>
      </div>
    </div>
  )
}

export default MainThank
