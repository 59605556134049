import React from 'react'
import style from './MainBlock.module.css'
const MainBlock = () => {
  return (
    <div className={style.main}>
      <div className={`${'customContainer'} ${style.main_wrap}`}>
        <h2 className={style.main_title}>Windows and Doors</h2>
        <p className={style.main_desc}>From Europe to You</p>
      </div>
    </div>
  )
}

export default MainBlock
