import React, { useState, useEffect } from 'react'
import './offerButton.css'
import { PopUp } from '../../Pages/LandingPage/PopUp'
import { Button, useDisclosure } from '@nextui-org/react'

const OfferButton = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const modalState = useDisclosure()
  const { onOpen } = modalState

  useEffect(() => {
    const firstBlock = document.getElementById('first-block') // Make sure the first block has this ID in HTML
    if (!firstBlock) return

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        setIsButtonVisible(!entry.isIntersecting) // Show button when first block is out of view
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: '-410px', // Trigger when 20px out of view
        threshold: 0, // Trigger as soon as the first block leaves the view
      }
    )

    observer.observe(firstBlock)

    return () => observer.disconnect()
  }, [])

  return (
    <div>
      {isButtonVisible && (
        <div className="mobile-buttons">
          <Button
            variant="shadow"
            onClick={onOpen}
            className="mobile-button book-appointment"
          >
            Get in touch
          </Button>
          {modalState.isOpen && (
            <PopUp modalState={modalState} title="Get in touch" />
          )}
        </div>
      )}
    </div>
  )
}

export default OfferButton
