import React from 'react'
import styles from './Toastify.module.css'
import { Spinner } from '@nextui-org/react'
import { iconDone } from '../../Assets/Icons/LandingPage'

const Toastify = ({ type }) => {
  if (type === 'pending') {
    return (
      <div className={styles.wrap}>
        <Spinner color="default" />
        <div className={styles.wrap_text}>
          <h3 className={styles.wrap_text__title}>
            We are sending an application
          </h3>
          <p className={styles.wrap_text__subTitle}>
            The application process is in progress...
          </p>
        </div>
      </div>
    )
  }
  if (type === 'success') {
    return (
      <div className={styles.wrap}>
        <img src={iconDone} alt="done" />
        <div className={styles.wrap_text}>
          <h3 className={styles.wrap_text__title}>
            The application has been sent!
          </h3>
          <p className={styles.wrap_text__subTitle}>
            Your application has been successfully sent, our manager will
            contact you soon.
          </p>
        </div>
      </div>
    )
  }
}

export { Toastify }
