import React, { useState, useRef } from 'react'
import style from './Cases.module.css'
import {
  box1after,
  box1before,
  box2after,
  box2before,
  box3after,
  box3before,
} from '../../../Assets/Images/RepairsPage'

const imagesData = [
  {
    imgBefore: box1after,
    btnBefore: 'Slide to repair',
    imgAfter: box1before,
    btnAfter: 'After',
  },
  {
    imgBefore: box2after,
    btnBefore: 'Slide to repair',
    imgAfter: box2before,
    btnAfter: 'After',
  },
  {
    imgBefore: box3after,
    btnBefore: 'Slide to repair',
    imgAfter: box3before,
    btnAfter: 'After',
  },
]

const Cases = () => {
  const [sliderPositions, setSliderPositions] = useState(
    Array(imagesData.length).fill(35),
  )
  const [isDragging, setIsDragging] = useState(
    Array(imagesData.length).fill(false),
  )
  const containerRefs = useRef([])

  const startDragging = (index) => {
    const newIsDragging = [...isDragging]
    newIsDragging[index] = true
    setIsDragging(newIsDragging)
  }

  const stopDragging = (index) => {
    const newIsDragging = [...isDragging]
    newIsDragging[index] = false
    setIsDragging(newIsDragging)
  }

  const updateSliderPosition = (e, index) => {
    if (!isDragging[index]) return
    const container = containerRefs.current[index]
    const sliderWidth = container.clientWidth
    const newPosition =
      ((e.clientX - container.getBoundingClientRect().left) / sliderWidth) * 100
    const newSliderPositions = [...sliderPositions]
    newSliderPositions[index] = Math.max(0, Math.min(100, newPosition))
    setSliderPositions(newSliderPositions)
  }

  const handleMouseMove = (e, index) => {
    if (!isDragging[index]) return
    updateSliderPosition(e, index)
  }

  const handleTouchMove = (e, index) => {
    if (!isDragging[index]) return
    const touch = e.touches[0]
    updateSliderPosition({ ...e, clientX: touch.clientX }, index)
  }

  return (
    <div className={style.cases}>
      <div className="customContainer">
        <div className={style.titles}>
          <hr className={style.titles_line} />
          <h2 className={style.titles_title}>Cases</h2>
          <hr className={style.titles_line} />
        </div>
        <div className={style.boxes}>
          {imagesData.map((images, index) => (
            <div
              key={index}
              ref={(el) => (containerRefs.current[index] = el)}
              className={style.sliderWrapper}
              onMouseMove={(e) => handleMouseMove(e, index)}
              onTouchMove={(e) => handleTouchMove(e, index)}
              onMouseDown={() => startDragging(index)}
              onMouseUp={() => stopDragging(index)}
              onTouchStart={() => startDragging(index)}
              onTouchEnd={() => stopDragging(index)}
            >
              <div
                className={`${style.sliderImage} ${style.sliderBefore}`}
                style={{
                  backgroundImage: `url(${images.imgBefore})`,
                  clipPath: `inset(0 ${100 - sliderPositions[index]}% 0 0)`,
                }}
              >
                <button className={style.boxes_btn}>{images.btnAfter}</button>
              </div>
              <div
                className={`${style.sliderImage} ${style.sliderAfter}`}
                style={{ backgroundImage: `url(${images.imgAfter})` }}
              >
                <button className={style.boxes_btn}>{images.btnBefore}</button>
              </div>
              <div
                className={style.sliderHandle}
                style={{ left: `${sliderPositions[index]}%` }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { Cases }
