import React, { useState, useEffect, useCallback } from 'react'
import styles from './Gallery.module.css'
import {
  galleryItem1,
  galleryItem2,
  galleryItem3,
  galleryItem4,
} from '../../../Assets/Images/LandingPage/Gallery/Gallery'

// Мемоізація компоненту для уникнення зайвих рендерів
const Gallery = React.memo(() => {
  const [galleryImages, setGalleryImages] = useState([])

  // Окрема функція для запиту з Instagram API
  const fetchInstagramPhotos = useCallback(async () => {
    try {
      const response = await fetch(
        `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&limit=50&access_token=IGQWRNQl8tUXdUczQ3MXhlNWthT3dacWhkSDg1Qk53X3pJZAWZAGaTFWaXh6UVRnTUJPdE5oUG1EeTE0SzVQYmU0eHhaRzBwUEpkU0tGZA3FUSm5XQU1HdW5kUzU4ZAnBmMUNONlFOMG91Y2ZA6OEt1OW1rMmptbm9qb00ZD`,
      )
      const data = await response.json()
      // Отримуємо тільки перші 5 елементів
      const imagesOnly = data.data.slice(0, 5)
      setGalleryImages(imagesOnly)
    } catch (error) {
      console.error('Error fetching Instagram photos:', error)
    }
  }, [])

  useEffect(() => {
    fetchInstagramPhotos()
  }, [fetchInstagramPhotos])

  // Рендер зображень або відео
  const renderMedia = (media, order) => {
    if (media.media_type === 'IMAGE' || media.media_type === 'CAROUSEL_ALBUM') {
      return (
        <img
          src={media.media_url}
          alt="Gallery media"
          className={
            order === 'first'
              ? styles.gallery_wrap1__main
              : styles.gallery_wrap1__second
          }
        />
      )
    }
    return (
      <video
        className={
          order === 'first'
            ? styles.gallery_wrap1__main
            : styles.gallery_wrap1__second
        }
        controls={false}
        autoPlay
        muted
        playsInline
        loop
        src={media.media_url}
      />
    )
  }

  return (
    <div className={styles.gallery}>
      <div className={`customContainer ${styles.gallery_wrap}`}>
        <div className={styles.gallery_wrap1}>
          {galleryImages.length > 0 && (
            <a
              href={galleryImages[0].permalink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.gallery_wrap1__main}
            >
              {renderMedia(galleryImages[0], 'first')}
            </a>
          )}
          <div className={styles.gallery_wrap1__block}>
            {galleryImages.slice(1, 4).map((item) => (
              <a
                href={item.permalink}
                key={item.id}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.gallery_wrap1__second}
              >
                {renderMedia(item, 'second')}
              </a>
            ))}
          </div>
        </div>
        <div className={styles.gallery_wrap2}>
          <h2 className={styles.gallery_wrap2__title}>
            Follow our journey on{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/xleum.construction?igsh=MXh1M2QxNTI0M3NvNA%3D%3D"
            >
              Instagram!
            </a>
          </h2>
          <p className={styles.gallery_wrap2__desc}>
            See beautiful things come together with behind-the-scenes, project
            highlights, and updates on our latest work. Get inspired with the
            quality and creativity we bring to every project.
          </p>
        </div>
        <div className={styles.gallery_wrap2__wrapper}>
          <img
            src={galleryItem1}
            alt=""
            className={styles.gallery_wrap2__img1}
          />
          <div className={styles.gallery_wrap2__item}>
            <img
              src={galleryItem2}
              alt=""
              className={styles.gallery_wrap2__item_img}
            />
            <img
              src={galleryItem3}
              alt=""
              className={styles.gallery_wrap2__item_img}
            />
          </div>
          <img
            src={galleryItem4}
            alt=""
            className={styles.gallery_wrap2__img2}
          />
        </div>
      </div>
    </div>
  )
})

export default Gallery
