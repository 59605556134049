import React from 'react'
import AboutWindows from './AboutWindows/AboutWindows'
import EuropaenWin from './EuropeanWindows/EuropeanWin'
import MainBlock from './MainBlock/MainBlock'
import { Gallery } from './Gallery/Gallery'

const WindowsPage = () => {
  return (
    <div>
      <MainBlock />
      <AboutWindows />
      <EuropaenWin />
      <Gallery />
    </div>
  )
}

export default WindowsPage
