import React from 'react'
import CardLeft from '../../../Components/CardLeft/CardLeft'
import {
  VinylLogo1,
  VinylLogo2,
} from '../../../Assets/Icons/SidingPage/Vinyl/Index'
import style from './Vinyl.module.css'
import Title from '../../../Components/Title/Title'
import CardImg from '../../../Components/CardImg/CardImg'
import {
  VinylImg1,
  VinylImg2,
  VinylImg3,
} from '../../../Assets/Images/SidingPage/Vinil/Vinyl'
const Vinyl = () => {
  const cardData = {
    description:
      'Vinyl siding is a highly popular choice due to its affordability and durability. It stands up well to harsh weather conditions, resists fading, and is less prone to showing scratches, making it a low-maintenance solution that keeps properties looking fresh for years. Available in a wide range of styles, from classic to modern, vinyl siding offers versatility and can enhance the architectural appeal of any building. Additionally, vinyl siding is energy-efficient, providing insulation that reduces heat loss and helps maintain comfortable indoor temperatures, ultimately leading to lower energy bills.',
    features: ['Weather Resistant', 'Low Maintenance', 'Affordable'],
    logos: [VinylLogo1, VinylLogo2],
  }
  const cardItems = [
    {
      src: VinylImg1,
      alt: 'Main Image',
      hoverText:
        'Horizontal Vinyl Siding in Ironstone Color by Westlake Royal Building Products',
    },
    {
      src: VinylImg2,
      alt: 'Image 1',
      hoverText:
        'Vertical Board and Batten Vinyl Siding in Sterling Color by Westlake Royal Building Products',
    },
    {
      src: VinylImg3,
      alt: 'Image 2',
      hoverText:
        'Horizontal VInyl Siding in Rockslide Color by Westlake Royal Building Products',
    },
  ]

  return (
    <div className={style.vinyl} id={'vinyl'}>
      <div className={'customContainer'}>
        <Title title={'Vinyl Siding'} />
        <div className={style.vinyl_wrap}>
          <CardLeft data={cardData} isFirstSvg={false} />
          <CardImg items={cardItems} />
        </div>
      </div>
    </div>
  )
}

export default Vinyl
