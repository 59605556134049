import React from 'react'
import style from './MainBlock.module.css'
const MainBlock = () => {
  return (
    <div className={style.main}>
      <div className={`${'customContainer'} ${style.main_wrap}`}>
        <h2 className={style.main_title}>Our Developments</h2>
        <p className={style.main_desc}>
          New Construction Projects & Modern Luxury Apartment Rentals
        </p>
      </div>
    </div>
  )
}

export default MainBlock
