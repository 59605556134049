import React from 'react'
import { Gallery } from './Gallery/Gallery'
import PartnersDev from './PartnersDev/PartnersDev'
import MainBlock from './MainBlock/MainBlock'
import Form from '../../Modules/Form/Form'

const Repairs = () => {
  return (
    <div>
      <MainBlock />
      <PartnersDev />
      <Gallery />
      <Form />
    </div>
  )
}

export default Repairs
