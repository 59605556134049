import React from 'react'
import style from './Gallery.module.css'
import {
  gallery_1_1,
  gallery_1_2,
  gallery_1_3,
  gallery_2_1,
  gallery_2_2,
  gallery_2_3,
} from '../../../Assets/Images/WindowsPage'

const images = [
  { src: gallery_1_1, alt: 'gallery_1_1', className: style.box_11 },
  { src: gallery_1_2, alt: 'gallery_1_2', className: style.box_12 },
  { src: gallery_1_3, alt: 'gallery_1_3', className: style.box_13 },
  { src: gallery_2_1, alt: 'gallery_2_1', className: style.box_21 },
  { src: gallery_2_2, alt: 'gallery_2_2', className: style.box_22 },
  { src: gallery_2_3, alt: 'gallery_2_3', className: style.box_23 },
]

const Gallery = () => (
  <div className={style.gallery}>
    <div className="customContainer">
      <div className={style.titles}>
        <hr className={style.titles_line} />
        <h2 className={style.titles_title}>Gallery</h2>
        <hr className={style.titles_line} />
      </div>
      <div className={style.box}>
        {images.map(({ src, alt, className }) => (
          <img key={alt} src={src} alt={alt} className={className} />
        ))}
      </div>
    </div>
  </div>
)

export { Gallery }
