import React from 'react'
import './partnersdev.css'

const PartnersDev = () => {
  return (
    <div className="partnersdev_block">
      <div className="customContainer ">
        <div className="partnersdev_title_block">
          <div>
            <h1 className="partnersdev_title">
              Investing in Philadelphia’s Future
            </h1>
          </div>
          <div className="partnersdev_title_line"></div>
        </div>
        <div className="partnersdev_content">
          <div className="partnersdev_content_left">
            <p className="partnersdev_content_text">
              We specialize in real estate services across Philadelphia and its
              surrounding areas. From rentals and sales to the construction of
              new properties, we’re driven by a passion to create spaces that
              enhance everyday life. Each project we undertake is more than just
              a building; it's a commitment to premium quality. Our portfolio
              includes newly built homes and apartment complexes, all developed
              with the highest standards.
            </p>

            <div className="partners_yellow_block">
              <div className="partners_yellow_line"></div>
              <div className="partners_yellow_text_block">
                <p className="partnersdev_text_yellow">
                  Realtors and wholesalers, if you have deals in the works,
                  let’s connect. We’re always looking for new opportunities to
                  collaborate and transform properties into valuable
                  investments.
                </p>
                <p className="partnersdev_text_yellow">
                  Let’s build something great together—reach out to us today to
                  start the conversation.
                </p>
              </div>
            </div>
          </div>
          <div className="partnersdev_content_right"></div>
        </div>
      </div>
    </div>
  )
}

export default PartnersDev
