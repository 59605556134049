import React from 'react'
import styles from './WhyUs.module.css'

const WhyUs = () => {
  return (
    <div className={styles.background}>
      <div className={`${'customContainer'} ${styles.whyUs}`}>
        <div className={styles.text}>
          <h1 className={styles.title}>Why Us</h1>
          <p className={styles.description}>
            At XLeum Construction, we specialize in delivering top-quality
            siding installation services and innovative exterior solutions for
            both residential and commercial projects. Rated as the fastest
            growing company in Philadelphia in 2023, our expertise extends
            across home renovations, property enhancements, and large-scale
            construction projects.
          </p>
          <p className={styles.description}>
            We bring ideas to life. Since 2018, XLeum Construction has been
            elevating Philadelphia properties, setting expectations higher with
            each completed project. Whether you need expert siding installation,
            premium European windows or doors, or professional services, we
            tailor our solutions to meet your specific needs. Our experience and
            commitment to innovating industry-leading standards is a promise
            that every project, whether large or small, is completed to the
            highest level of excellence.
          </p>
          {/*<Button className={styles.button} variant="light">*/}
          {/*  Learn More*/}
          {/*</Button>*/}
        </div>
        <div className={styles.image} />
      </div>
    </div>
  )
}

export default WhyUs
