import React from 'react'
import './footer.css'
import {
  FooterEmail,
  FooterFacebook,
  FooterInst,
  FooterLocation,
  FooterLogo,
  FooterNumber,
  FooterZnaesh,
} from '../../Assets/Images/LandingPage/Footer'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer_block">
      <div className="customContainer footer_content">
        <div className="footer_main_block">
          <div className="footer_info_block">
            <div className="footer_line_block">
              <div>
                <img
                  className="footer_logo"
                  src={FooterLogo}
                  alt="FooterLogo"
                />
              </div>
              <div className="footer_info_line">
                <img
                  className="footer_info_icon"
                  src={FooterLocation}
                  alt="FooterLocation"
                />
                <a
                  href="https://maps.app.goo.gl/BdW1VBCFgz4q5bGr9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer_info_text"
                >
                  7340 Milnor St Philadelphia PA 19139
                </a>
              </div>
              <div className="footer_info_line">
                <img
                  className="footer_info_icon"
                  src={FooterEmail}
                  alt="FooterEmail"
                />
                <a
                  href="mailto:info@xleumconstruction.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer_info_text"
                >
                  info@xleumconstruction.com
                </a>
              </div>
              <div className="footer_info_line">
                <img
                  className="footer_info_icon"
                  src={FooterNumber}
                  alt="FooterNumber"
                />
                <a
                  href="tel:267-521-2793"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer_info_text"
                >
                  267-521-2793
                </a>
              </div>

              <div className="footer_soc_block">
                <p className="footer_soc_text">Folow us:</p>{' '}
                <div className="footer_soc_icons_block">
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=61562950625131&mibextid=LQQJ4d"
                    >
                      <img
                        className="footer_soc_icon"
                        src={FooterFacebook}
                        alt="FooterFacebook"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/xleum.construction?igsh=MXh1M2QxNTI0M3NvNA%3D%3D"
                    >
                      <img
                        className="footer_soc_icon"
                        src={FooterInst}
                        alt="FooterInst"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_link_block">
              <div className="footer_block_link_text">
                <Link className="footer_link_text" to="/siding">
                  <p className="footer_link_text">Siding</p>
                </Link>
              </div>
              <div className="footer_block_link_text">
                <Link className="footer_link_text" to="/windows">
                  <p className="footer_link_text">Windows/Doors</p>
                </Link>
              </div>
              <div className="footer_block_link_text">
                <Link className="footer_link_text" to="/developments">
                  <p className="footer_link_text">Developments</p>
                </Link>
              </div>
              <div className="footer_block_link_text">
                <Link className="footer_link_text" to="/repairs">
                  <p className="footer_link_text">Repairs</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="contact_map">
            <iframe
              src={
                'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6110.550376400188!2d-75.029043!3d40.024635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6b44c550b3fbb%3A0x9e5962361f42b07b!2s7340%20Milnor%20St%2C%20Philadelphia%2C%20PA%2019136%2C%20USA!5e0!3m2!1sen!2sua!4v1722848671855!5m2!1sen!2sua'
              }
              style={{
                border: '0',
                width: '100%',
                height: '100%',
                borderRadius: '0px',
              }}
              title="contact_map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="footer_copyright">
          <div>
            <p className="footer_copyright_text"> Developed by</p>
          </div>
          <div>
            <a
              className="footer_copyright_link"
              href="https://www.znaesh.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="footer_copyright_icon"
                  src={FooterZnaesh}
                  alt="FooterZnaesh"
                />
              </div>
              <div>
                <p className={'footer_copyright_text_span'}>Znaesh Agency </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
