import React from 'react'
import './europeanwin.css'

const EuropaenWin = () => {
  return (
    <div className="europaenwin_block">
      <div className="customContainer ">
        <div className="europaenwin_title_block">
          <div>
            <h1 className="europaenwin_title">European Windows in USA</h1>
          </div>
          <div className="europaenwin_title_line"></div>
        </div>
        <div className="europaenwin_content">
          <div className="europaenwin_content_left">
            <p className="europaenwin_content_text">
              European windows are known for their great insulation, energy
              savings, and stylish look, with features like tilt-and-turn for
              better airflow and security. They’re built to handle all kinds of
              weather while cutting down on energy bills and giving your home a
              modern touch. XLeum Construction now brings these premium,
              top-quality windows directly to your doorsteps.
            </p>
          </div>
          <div className="europaenwin_content_right"></div>
        </div>
      </div>
    </div>
  )
}

export default EuropaenWin
