import React from 'react'
import style from './Numbers.module.css'
import {
  numbersIcon1,
  numbersIcon2,
  numbersIcon3,
} from '../../../Assets/Images/RepairsPage/Numbers/Index'

const Numbers = () => {
  const items = [
    {
      id: 1,
      number: '1,000,000+',
      imgSrc: numbersIcon1,
      title: 'sqft of siding completed',
    },
    {
      id: 2,
      number: '200+',
      imgSrc: numbersIcon2,
      title: 'satisfied customers',
    },
    {
      id: 3,
      number: '600+',
      imgSrc: numbersIcon3,
      title: 'projects completed',
    },
  ]

  return (
    <div className={style.numbers}>
      <div className={'customContainer'}>
        <div className={style.titles}>
          <hr className={style.titles_line} />
          <h2 className={style.titles_title}>Trust Us From Start To Finish</h2>
          <hr className={style.titles_line} />
        </div>
        <div className={style.numbers_wrap}>
          {items.map((item) => (
            <div key={item.id} className={style.numbers_item}>
              <span className={style.numbers_item__number}>{item.number}</span>
              <div className={style.numbers_item__wrap}>
                <img
                  src={item.imgSrc}
                  alt={item.title}
                  className={style.numbers_item__img}
                />
                <p className={style.numbers_item__title}>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Numbers
