import React from 'react'
import styles from './Partners.module.css'
import {
  icon,
  icon1,
  icon10,
  icon11,
  icon12,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
} from '../../../Assets/Images/LandingPage/Partners/Partners'
const Partners = () => {
  const partnersList = [
    { img: icon1 },
    { img: icon2 },
    { img: icon3 },
    { img: icon4 },
    { img: icon5 },
    { img: icon6 },
    { img: icon7 },
    { img: icon8 },
    { img: icon9 },
    { img: icon10 },
    { img: icon11 },
    { img: icon12 },
  ]
  return (
    <div className={styles.partners}>
      <div className={'customContainer'}>
        <div className={styles.title_wrap}>
          <h2 className={styles.title}>Partners</h2>
          <div className={styles.title_line}></div>
        </div>
        <div className={styles.desc_wrap}>
          <div className={styles.desc_line}></div>
          <p className={styles.desc}>
            Explore our trusted partners who supply high-quality materials for
            our construction projects. Our suppliers include:
          </p>
        </div>
        <div className={styles.partners_wrap}>
          <img src={icon} alt={'icon'} className={styles.main} />
          <div className={styles.second_wrap}>
            {partnersList.map((partner, index) => (
              <img
                className={styles.second}
                src={partner.img}
                key={index}
                alt={'logo'}
              />
            ))}{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
