import React from 'react'
import style from './MetalImg.module.css'
import {
  metalImg1,
  metalImg2,
  metalImg3,
  metalImg4,
} from '../../Assets/Images/SidingPage/Metal/Index'

const CardImg = ({ items, cardClass }) => {
  return (
    <div className={style.card}>
      <div className={style.card_wrap}>
        <div className={`${style.card_item} ${style.card1}`}>
          <img src={metalImg1} alt={''} className={style.card_img} />
          <span className={style.card_hover}>
            Black and Red Corrugated Metal Panel by Englert Metals custom bent
            in-house
          </span>
        </div>
        <div className={`${style.card_item} ${style.card2}`}>
          <img src={metalImg2} alt={''} className={style.card_img} />
          <span className={style.card_hover}>
            Silver Aluminum Composite Panel by EuroBond
          </span>
        </div>
      </div>
      <div className={style.card_wrap}>
        <div className={`${style.card_item} ${style.card2}`}>
          <img src={metalImg3} alt={''} className={style.card_img} />
          <span className={style.card_hover}>
            Silver and Green Corrugated Metal Panel by Englert Metals custom
            bent in-house
          </span>
        </div>
        <div className={`${style.card_item} ${style.card1}`}>
          <img src={metalImg4} alt={''} className={style.card_img} />
          <span className={style.card_hover}>
            Custom Bent Standing Seam Metal Panels by PacClad
          </span>
        </div>
      </div>
    </div>
  )
}

export default CardImg
