import React from 'react'
import style from './CardImg.module.css'

const CardImg = ({ items, cardClass }) => {
  return (
    <div className={style.card}>
      {items.slice(0, 1).map((item, index) => (
        <div key={index} className={`${style.card_item} ${cardClass}`}>
          <img src={item.src} alt={item.alt} className={style.card_img} />
          <span className={style.card_hover}>{item.hoverText}</span>
        </div>
      ))}
      <div className={style.card_wrap}>
        {items.slice(1).map((item, index) => (
          <div key={index} className={style.card_wrap__item}>
            <img
              src={item.src}
              alt={item.alt}
              className={style.card_wrap__img}
            />
            <span className={style.card_hover}>{item.hoverText}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CardImg
