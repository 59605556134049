import React from 'react'
import style from './Metal.module.css'
import Title from '../../../Components/Title/Title'
import CardLeft from '../../../Components/CardLeft/CardLeft'
import {
  weather1,
  weather2,
  weather3,
} from '../../../Assets/Images/SidingPage/Weather'
import {
  metalLogo1,
  metalLogo2,
  metalLogo3,
  metalLogo4,
} from '../../../Assets/Icons/SidingPage/Metal/Index'
import MetalImg from '../../../Components/MetalImg/MetalImg'

const Metal = () => {
  const cardData = {
    description:
      "Metal siding is a versatile and long-lasting option, frequently used in both residential and commercial construction. Manufactured from recyclable materials like steel or aluminum, metal siding offers significant benefits, including resistance to pests, rot, and moisture, along with the ability to last for decades. Its fire-resistant properties, due to its high melting point and natural oxide layer, further enhance its safety profile. Metal siding is also a cost-effective choice, often less expensive than wood, brick, or stone, while still offering longevity and requiring minimal maintenance. Additionally, it can be used as an accent feature, adding bold, eye-catching contrast that enhances a property's overall aesthetic.",
    features: [
      '100% RECYCLABLE',
      'FIRE-RESISTANT',
      'ENERGY AND COST EFFECTIVE',
    ],
    logos: [metalLogo1, metalLogo2, metalLogo3, metalLogo4],
  }
  const cardItems = [
    {
      src: weather1,
      alt: 'Main Image',
      hoverText: 'WeatherSmart Building wrap by the Henry Company',
    },
    {
      src: weather3,
      alt: 'Image 1',
      hoverText:
        'BlueSkin VP160 Self-Adhering Weather Resistive Barrier by the Henry Company',
    },
    {
      src: weather2,
      alt: 'Image 2',
      hoverText:
        'BlueSkin VP160 Self-Adhering Weather Resistive Barrier by the Henry Company',
    },
    {
      src: weather2,
      alt: 'Image 2',
      hoverText:
        'BlueSkin VP160 Self-Adhering Weather Resistive Barrier by the Henry Company',
    },
  ]
  return (
    <div className={style.metal} id={'metal'}>
      <div className={'customContainer'}>
        <Title title={'Metal Siding'} />
        <div className={style.metal_wrap}>
          <CardLeft
            data={cardData}
            isFirstSvg={false}
            imgClass={style.metal_img}
          />
          <MetalImg items={cardItems} cardClass={style.metal_order} />
        </div>
      </div>
    </div>
  )
}

export default Metal
