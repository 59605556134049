import React from 'react'
import Video from '../../../Components/Video/Video'
import styles from './MainBLock.module.css'
import { Button, useDisclosure } from '@nextui-org/react'
import { PopUp } from '../PopUp'
const MainBlock = () => {
  const modalState = useDisclosure()
  const { onOpen } = modalState
  return (
    <div id="first-block">
      <div className={styles.main_bg} >
        <Video />
        <div className={`${'customContainer'} ${styles.mainContainer}`}>
          <h1 className={styles.title}>Premier Siding Installers</h1>
          <p className={styles.sub}>
            Guaranteed Top Quality Service to Elevate Each Project to Its Best
            Potential
          </p>
          <Button className={styles.button} variant="shadow" onClick={onOpen}>
            Get a Quote
          </Button>
        </div>
        <PopUp modalState={modalState} title={'Get a Quote'} />
      </div>
    </div>
  )
}

export default MainBlock
